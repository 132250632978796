import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { baseColors, breakpoints } from '../../constants';
import { deriveRgbaFromHex } from '../../utils';

export const Wrapper = styled.header`
  position: relative;
  margin: 1.5rem 0;
  padding: ${props => props.imgSrc ? '4rem 1rem' : '1rem'};
  text-align: center;
  ${props => !props.imgSrc && 'border-radius: 1rem 1rem 0 0'};
  ${props => props.theme.light && `
    background-color: ${props.theme.light};
    background-image: linear-gradient(
      to bottom,
      ${deriveRgbaFromHex(props.theme.light,1)} 0%,
      ${deriveRgbaFromHex(props.theme.light,1)} 75%,
      rgba(255,255,255,1) 100%
    );
  `}

  @media screen and (min-width: ${breakpoints.bp2}) {
    padding: ${props => props.imgSrc ? '4rem 2rem' : '2rem'};
  }

  @media screen and (min-width: ${breakpoints.bp3}) {
    margin: ${props => props.theme.light ? '3rem 0 0 0' : '3rem 0'};
    padding: ${props => props.imgSrc ? '8rem 4rem' : '4rem'};
  }

  ${props => props.imgSrc && `
    background-image: url('${props.imgSrc}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    
    > * {
      position: relative;
      z-index: 1;
    }

    h1 {
      color: ${baseColors.white};
    }

    p {
      color: ${baseColors.grey.base};
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      z-index: 1;
    }
  `}
`;

export const Title = styled.h1`
  margin-bottom: 0;
  font-size: 2rem;

  @media screen and (min-width: ${breakpoints.bp3}) {
    font-size: 2.6rem;
  }
`;

export const Excerpt = styled.p`
  max-width: 900px;
  margin: .5rem auto 0 auto;
  font-size: 1rem;
  color: ${baseColors.grey.dark};

  @media screen and (min-width: ${breakpoints.bp3}) {
    font-size: 1.2rem;
  }
`;

export const Cta = styled(Link)`
  display: inline-block;
  margin-top: 1rem;
  font-size: .75rem;
  color: ${baseColors.black};
`;

export const BannerMetaWrapper = styled.div`
  font-size: .7rem;
`;

export const Author = styled.p`
  margin: 1rem 0 2rem 0;
  color: ${baseColors.grey.dark};
`;

export const Category = styled(Link)`
  position: absolute;
  top: -.6rem;
  left: 50%;
  transform: translateX(-50%);
  padding: .25rem .75rem;
  font-size: .7rem;
  text-decoration: none;
  background-color: ${props => props.theme.base};
  border-radius: 1rem;
  transition: background-color .15s ease-out;

  &:hover {
    background-color: ${props => props.theme.dark};
  }
`;
