import React from 'react';
import { Tag } from '../Post/styled';
import { BannerMetaWrapper, Author } from './styled';
import { deriveDatetimeFromString } from '../../utils';

const BannerMeta = ({
  author,
  datetime,
  tags
}) => {
  const isBannerMetaRequired = !!((author && datetime) || tags.length > 0);

  return (
    <React.Fragment>
      {isBannerMetaRequired &&
        <BannerMetaWrapper>
          {author && datetime &&
            <Author>
              By {author} on <time dateTime={datetime}>{deriveDatetimeFromString(datetime)}</time>
            </Author>
          }
          {tags.length > 0 && 
            <p>
              {tags.map((tag, index) => <Tag to={tag.path} key={tag.title}>{tag.title}</Tag>)}
            </p>
          }
        </BannerMetaWrapper>
      }
    </React.Fragment>
  );
};

export default BannerMeta;
