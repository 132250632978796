import React from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from '@emotion/react';
import BannerMeta from './BannerMeta';
import { Wrapper, Title, Excerpt, Cta, Category } from './styled';
import { brandColors } from '../../constants';
import { deriveSlugFromString } from '../../utils';

export const fragment = graphql`
  fragment PAGE_CONTENT_FRAGMENT on MarkdownRemark {
    html
    frontmatter {
      datetime
      title
      excerpt
      author
      category
      tags
    }
  }
`;

const Banner = ({
  title,
  excerpt = '',
  link: {
    href = '',
    text = ''
  } = {},
  datetime = '',
  author = '',
  category = {},
  tags = [],
  imgSrc = ''
}) => {
  const deriveBannerTheme = () => {
    if (category.title) {
      return { ...brandColors[deriveSlugFromString(category.title)] };
    }

    return {};
  };

  return (
    <ThemeProvider theme={deriveBannerTheme}>
      <Wrapper imgSrc={imgSrc}>
        <Title>{title}</Title>
        {excerpt && <Excerpt>{excerpt}</Excerpt>}
        {href && text && <Cta to={href}>{text}</Cta>}
        <BannerMeta
          author={author}
          datetime={datetime}
          tags={tags}
        />
        {category.path && <Category to={category.path}>{category.title}</Category>}
      </Wrapper>
    </ThemeProvider>
  );
}

export default Banner;
